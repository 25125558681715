body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}



* {
  font-family: "Verdana", cursive, sans-serif;  
  color: white;
}

body {
  background-color: #252d4a;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  min-height: 100vh;
}
.app {
  background-color: #252d4a;
  width: 450px;
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  padding: 20px;
  /* box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75); */
  display: flex;
  justify-content: space-evenly;
  max-width: 440px;
  
  
}

.question-text {
  margin-bottom: 12px;
}

.custom-bottom-sheet {
  background-color: #7cc6fe;
  width: 100%; /* Make it resizable with screen size */
  max-width: 440px;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column; /* Adjusted to make content stack vertically */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.custom-bottom-sheet__body {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .custom-bottom-sheet {
    display: unset;
  }
}

.ReactSwipeableBottomSheet--open {
  -webkit-overflow-scrolling: touch;
}
