.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-list {
    display: flex;
    flex-direction: column;
  }
  .item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 20px 0;
    border-bottom: 1px #fbc1bb solid;
  }

  .item-name {
    cursor: pointer;
  }
  
  .item-name svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  .quantity {
    display: flex;
    align-items: center;
    border: 1px solid #ec645b;
    background: white;
    border-radius: 50px;
    font-size: 12px;
    color: #ec645b;
    min-width: 70px;
    justify-content: space-between;
  }

  
 .radio-button {
  display: inline-block;
  align-items: center;
}

.radio-button input[type="radio"] {
  display: inline-block;
}

.radio-button label {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.radio-button input[type="radio"]:checked + label::after {
  content: "X";
  font-weight: bold;
}

  .modalContainer {
    position: absolute;
  width: 500px;
  height: 200px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: #ffffff;
  padding: 20px;
  max-width: 240px;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  overflow: auto;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }