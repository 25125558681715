/* ChatBubble.css */

.bubble {
    display: inline-block;
    padding: 8px;
    border-radius: 20px;
    margin: 8px;
    max-width: 100%;
  }
  
  .Provider {
    background-color: rgb(90, 90, 90);
   
  }
  
  .Patient {
    background-color: rgb(121, 165, 248);
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  