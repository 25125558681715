.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.hero__title {
  position: absolute;
  bottom: 15%;
  right: 10%;
  color: white;
  font-size: 4rem;
  padding: 15px;
}
