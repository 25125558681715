
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  overflow: hidden;
  color: white;
  background-position: center !important;
  background-size: contain !important;
}

.site-layout {
  position: relative;
}

.site-layout .site-layout-header {
  background: #fff;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  box-shadow: 0px 6px 20px -5px rgba(0, 0, 0, 0.2);
  z-index: 20;
}

.ant-layout-header {
  padding: 0 !important;
}

/* page transition aniation */

.container {
  position: relative;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@media (max-width: 575px) {
  .page-enter {
    transform: none;
  }

  .page-enter-active {
    transform: none;
  }

  .page-exit {
    transform: none;
  }

  .page-exit-active {
    transform: none;
  }

  .footer {
    margin-bottom: 50px;
  }
  .ant-layout-footer {
    padding: 20px !important;
  }
}

/* ----- */

.ant-descriptions-title {
  margin-top: 20px;
}

.ant-table-pagination {
  float: unset !important;
  text-align: center !important;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  display: flex;
  flex-direction: row;
}


.ant-typography, .ant-menu, .ant-menu-item, .ant-menu-submenu-title, .ant-popover-inner-content, .ant-popover-message {
  color: white;
}

/* ----- */

.overlay {
  z-index: 999;
  background: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay-anim-enter {
  opacity: 0;
}
.overlay-anim-enter-active {
  opacity: 0.5;
  transition: opacity 200ms;
}
.overlay-anim-exit {
  opacity: 0.5;
}
.overlay-anim-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/* ----- */

.statPadding {
  padding: 40px;
}

@media (max-width: 575px) {
  .statPadding {
    padding: 10px;
  }
}

.btn {
    border-radius: 0;
}

.btn + .btn {
    margin-left: 10px;
}

.option {
    background-color: linear-gradient(315deg, #2E325A 20%, #0E112A 100%);
    cursor: pointer;
    font-size: 20px;
    margin: 4px;
}
.button0{
    margin: 5px;
    border:none;
    width: 20%;
    height: 30px;
    border-radius: 40px;
  }

  .button0:hover{
    background-color: #576b92;
    color: aliceblue;
  }
  
  button0:focus{
    background-color: brown;
    color: aliceblue;
    outline: none;
  
  }
  .dont-break-out {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }

.option label {
    cursor: pointer;
    display: block;
    padding: 10px;
     width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  /* border: 5px solid #234668; */
  cursor: pointer;
}

#quiz .options input[type=checkbox] {
    height: 22px;
    margin-right: 6px;
    width: 22px;
      
}

.cursor-pointer {
    cursor: pointer;
}

.result-question {
    background-color: #eee;
    margin: 4px;
    padding: 6px;
}

/* Create Quiz Styles */
.create-quiz .question {
    text-align: left;
}

.create-quiz input[type=text] {
    border: 1px solid rgb(221, 221, 221);
    width: 95%;
}

.create-quiz .tb-quiz-name {
    background-color: #ccffcc;
    text-align: center;
}

.create-quiz .tb-desc {
    background-color: #fff;
}

.create-quiz .options > div {
    margin-left: 20px;
}

.create-quiz .options input[type=checkbox] {
    border: 1px solid #262626;
    display: inline-block;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    width: 20px;
}

@media screen and (max-width: 767px) {
    .my-container {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .my-container {
      width: 50%;
    }
  }
@media only screen and (max-width: 480px) {
    h1, .h1 {
        font-size: 22px;
    }

    h2, .h2 {
        font-size: 20px;
    }

    h3, .h3 {
        font-size: 18px;
    }

    .option {
        font-size: 16px;
        padding: 6px;
    }
}

.question-section {
    width: 100%;
    position: relative;
    display: 'flex';
  }

  .UserInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0px;
    margin-top: 20px;
    position: relative;
  
    &:hover .UserInfoLine {
      background: linear-gradient(to right, purple, green);
      width: 100%; /* Ensure the background covers the entire width on hover */
    }
  
    &:hover .IconStack {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .UserProfile {
    overflow: hidden;
  }
  
  .UserInfo .UserInfoLine {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Set an initial width */
    height: 1px;
    background: linear-gradient(to right, purple, green);
    transition: transform 0.3s ease, width 0.3s ease; /* Added width transition */
  }
  
  .UserInfo:hover .UserInfoLine {
    transform: translateX(65%);
    width: 0; /* Adjust the width to your desired final width */
  }
  
  .UserInfo input[type="text"] {
    width: 330px;
    height: 40px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }
  
  .UserInfo:hover .IconStack {
    visibility: visible;
  }
  
  .IconStack {
    display: flex;
    flex-direction: row;
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .UserInfo:hover .IconStack {
    opacity: 1;
    transform: translateX(0);
  }
  
  .UserInfo button {
    background-color: #852d4a;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .UserInfo input[type="text"]::placeholder {
    font-size: 12px;
    color: #ffffff;
  }
  
.search-form {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  
  .search-form input {
    padding: 5px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .search-results {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .search-results div {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  .dialog-container {
    background-color: #f2f2f2;
    padding: 20px;
  }
  
  .dialog {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .dialog-item {
    display: flex;
    margin-bottom: 10px;
  }
  
  .dialog-text {
    margin: 0;
    padding: 10px;
    border-radius: 10px;
  }
  
  .doctor-text {
    background-color: #fff;
  }
  
  .patient-text {
    background-color: #d1e0ff;
  }

  .card_item {
    padding: 15px 30px;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-top: 20px;
  }
  
  
  .container .card_item:hover {
      transform: scale(0.97);
      transition: transform 0.3s;
      cursor: pointer;
  }
  
  .container .card_inner {
    background: rgb(253, 253, 253);
    border-radius: 10px;
    padding: 35px 20px;
    min-width: 225px;
    min-height: 315px;
    max-height: 370px;
    width: 100%;
    box-shadow: 5px 5px 10px 2px rgba(95, 95, 95, 0.24);
  }
  
  .container .card_item img {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
    border-radius: 50%;
    align-items: center;
    transition: transform .2s;
  }
  
  .container .card_item .userName {
    color: #203150;
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .container .card_item .userUrl {
    font-size: 12px;
    font-weight: 100;
    margin: 5px 0 10px;
    word-wrap: break-word;
    color: #888B9E;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .container .card_item .gitDetail {
    font-size: 14px;
    line-height: 24px;
    color: #7b8ca0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .detail-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .detail-box span {
    margin: 2px;
    padding: 2px 10px;
    /* border: 2px solid #c7e6fa; */
    background-color: #f0ebfa;
    border-radius: 50px;
    font-size: 10px;
    font-weight: 600;
    color: #788697;
  }
  
  .seeMore {
    margin: 10px auto;
    width: 100px;
    height: 35px;
    background: hsl(245, 44%, 65%);
    color: #fff;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 10px 10px hsl(225, 100%, 94%);
  }
  
  @media screen and (max-width: 1024px) {
    .container .card_item {
      width: 33%;
      display: block;
    }
  }
  
  @media screen and (max-width: 768px) {
    .container .card_item {
      width: 50%;
    }
    .wrapper .header {
      font-size: 16px;
      height: 50px;
    }
  }
  
  @media screen and (max-width: 568px) {
    .container .card_item {
      width: 80%;
      margin: auto;
    }
  }
  
  @media screen and (max-width: 320px) {
    .container .card_item {
      width: 100%;
    }
    
  }